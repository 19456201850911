import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row, Button } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FInput, FSelect } from "CommonElements/Form";
import CommonModal from "../../../Common/Data/Ui-kits/Modals/common/modal";
import { transactionsService, clientService } from "Services";
import { toast } from "react-toastify";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ModalFooter } from "reactstrap";
import { Close, SaveChanges } from "../../../../Constant";
import Swal from "sweetalert2";
import gateways from '../../../Common/Component/Gateways';
import { useSelector } from "react-redux";

const ApprovedFormModal = (
  {
    approvedModal,
    NewMessage,
    toggle,
    setApprovedModal,
    approvedData,
    refreshRecord,
  },
  props
) => {
  const { data = {} } = props;
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const [copied, setCopied] = useState(false);
  const [netDeposit, setNetDeposit] = useState('');
  const [bankDetail, setbankDetail] = useState('');
  const [walletDetail, setWalletDetail] = useState('');
  const [groupPathType, setGroupPathType] = useState('');
  const [equity, setEquity] = useState('');
  const [modal, setModal] = useState(false);
  const [lastDeposit, setLastDeposit] = useState('');
  const [gateWay, setGateWay] = useState([]);
  const [transactionType, setTransactionType] = useState('');
  const [totalNetDeposit, setTotalNetDeposit] = useState('');
  const [ibType, setIbType] = useState('');
  const [salePerson, setSalePerson] = useState('');
  const [parentName, setParentName] = useState(''); 

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    getValues,
    setValue,
    control,
  } = useForm();
  const resetForm = () => {
    return reset({
      amount: "",
      reason: "",
      // fee: "",
      note: "",
    });
  };
  useEffect(() => {
    if (!approvedModal) {
      clearErrors();
      resetForm();
    } else {
      const formFields = getValues();

      console.log('Withdrawal details is ', approvedData, approvedData['Account.login']);

      transactionsService
        .getTransactionDetailsWithDrawals({ accountId: approvedData['Account.id'], login: approvedData['Account.login'], customerId: approvedData['Account.Customer.id'] })
        .then((res) => {
          console.log('Transaction Details ', res);
          const netDepo = res.totalDeposits - res.totalWithdrawals;
          setNetDeposit(netDepo);
          setbankDetail(res.bankRecord && `${res.bankRecord.bankName} ${res.bankRecord.iban}`);
          setWalletDetail(res.walletRecord && `${res.walletRecord.network}   ${res.walletRecord.address}`);
          setGroupPathType(res.groupPath && res.groupPath.type);
          setEquity(res.equityData && res.equityData);
          setLastDeposit(res.lastDepositAmount && res.lastDepositAmount);
          setIbType(res.customerRecord && res.customerRecord);
          setSalePerson(res.salePersonName && res.salePersonName);
          setParentName(res.parentName && res.parentName);
        })
        .catch((err) => {
          console.log("error", err);
        });
      Object.keys(formFields).forEach((key) => {
        if (approvedData[key]) {
          setValue(key, approvedData[key]);
        }
      });
    }
  }, [approvedModal]);

  useEffect(() => {
    if (approvedModal) {
      transactionsService.getTransactions({ customerId: approvedData['customerId'], type: 'DEPOSIT' }).then((res) => {
        let seenGateways = new Set();
        let uniqueData = res.data && res.data.filter(item => {
          if (seenGateways.has(item.gateway)) {
            return false;
          } else {
            seenGateways.add(item.gateway);
            return true;
          }
        });
        setGateWay(uniqueData);
      }).catch(err => {
      });
    }
  }, [approvedModal])

  useEffect(() => {
    if (approvedModal) {
      transactionsService.getTransactionDepositValue({ accountId: approvedData['accountId'] }).then((res) => {
        setTotalNetDeposit(res)
      }).catch((err) => console.log(err));
    }
  }, [approvedModal])

  const onSubmit = (data) => {

    if (data !== "") {
      setSubmitState({ loading: true });
      const payload = {
        // id:approvedData.id,
        note: data.reason,
        reason: data.reason,
        gateway: data.gateway
        // fee: data.fee.toString(),
      };

      Swal.fire({
        title: "Are you sure you want to Approve?",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: true,
        showCancelButton: true,
        text: "Once approved, you will not be able to approve this request!",
        icon: "success",
      }).then((res) => {
        if (res.isConfirmed) {
          setModal(false);
          transactionsService
            .postTransactionApproved(payload, approvedData.id)
            .then((res) => {
              setSubmitState({
                loading: false,
              });
              refreshRecord();
              setApprovedModal(false);
              toast.success("WithDrawal Added Successfully");
            })
            .catch((err) => {
              setSubmitState({
                loading: false,
              });
              toast.error("WithDrawal Failed ");
            });
        } else {
          console.log("errror");
        }
      });

    }
  };
  const CopyText = 'Copy Data';

  const handleCopy = () => {
    setCopied(true);
    toast.success('Copied to clipboard!');
  };

  const formValueChange = (name, value) => {
    setValue(name, value);
    if (name === 'transactionType') {
      setTransactionType(value);
    }
  };

  const rowContent = `
  Withdrawal Request of USD: ${approvedData.amount}
  Trading Account ID: ${approvedData['Account.login']}
  Client Name: ${approvedData['Account.Customer.firstName']} ${approvedData['Account.Customer.lastName']}
  Withdrawal Method: ${approvedData.gateway === 'Crypto' ? (walletDetail ? walletDetail : 'No wallet detail available') : (bankDetail ? bankDetail : 'No withdrawal method available')}
  Previous Deposits: 
  ${gateWay.map((item, index) => `- ${item.gateway}`).join('\n')}
  Entity: ${approvedData['Account.Customer.entity']}
  STP/WH: ${groupPathType}
  SalePerson: ${salePerson ? `${salePerson.firstName} ${salePerson.lastName}` : 'No Sale Person Name'}
  IB Name: ${parentName ? `${parentName.firstName} ${parentName.lastName}` : 'No IB Name'}
  IB Type: ${ibType ? ibType.idType : 'No IB Type'}
  Current Equity: ${equity ? equity : 'No equity data available'}
  Net Deposits: ${totalNetDeposit ? totalNetDeposit.netDeposit : 'No net deposit data available'}
`;


  const roleEntity = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.entity) || {}
  );

  console.log('sale: ', salePerson);

  return (
    <Fragment>
      <CommonModal
        isOpen={approvedModal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <FInput
                errors={errors}
                type="number"
                label="Amount:"
                name="amount"
                value={data.amount}
                register={register}
                placeholder="Enter your amount"
                validation={{ required: true }}
              // disabled
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <FInput
                errors={errors}
                label="Reason:"
                name="reason"
                value={data.reason}
                register={register}
                placeholder="Enter Reason"
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <FSelect
                control={control}
                errors={errors}
                label='Gateway'
                name='gateway'
                register={register}
                placeholder='Enter gateway'
                options={roleEntity && Object.values(roleEntity).length !== 0 ? [{ name: 'Local Withdrawal Morocco', value: 'Local Withdrawal Morocco' }] : gateways.filter(item => item.key === 0 || item.key === 2)}
                validation={{ required: true }}
                value={''}
                setValue={formValueChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md="12">
              <div>
                <p><strong>Withdrawal Request of USD:</strong> ${approvedData.amount}</p>
                <p><strong>Trading Account ID:</strong> {approvedData['Account.login']}</p>
                <p><strong>Client Name:</strong> {approvedData['Account.Customer.firstName']} {approvedData['Account.Customer.lastName']}</p>
                <p>
                  <strong>Withdrawal Method: </strong>
                  {approvedData['gateway'] === 'Crypto' ? walletDetail && walletDetail : (bankDetail ? bankDetail : 'No withdrawal method available')}
                </p>
                {/* <p>
                  <strong>Withdrawal Method: </strong>
                  {bankDetail && walletDetail ? `${bankDetail} and ${walletDetail}` : (bankDetail ? bankDetail : (walletDetail ? walletDetail : 'No withdrawal method available'))}
                </p> */}
                {/*<p><strong>IB Client:</strong> Salem Ahmad salem 537831_</p>*/}
                <p><strong>Previous Deposits:</strong>{gateWay.map((item) => item.gateway).join(', ')}</p>
                <p><strong>Entity:</strong> {approvedData['Account.Customer.entity']}</p>
                <p><strong>STP/WH:</strong> {groupPathType}</p>
                <p><strong>SalePerson:</strong> {salePerson && salePerson.firstName} {salePerson && salePerson.lastName}</p>
                <p><strong>IB Name:</strong> {parentName && parentName.firstName} {parentName && parentName.lastName}</p>
                <p><strong>IB Type:</strong> {ibType && ibType.ibType}</p>
                <p><strong>Current Equity:</strong> ${equity}</p>
                <p><strong>Net Deposits:</strong> ${totalNetDeposit.netDeposit}</p>
              </div>
            </Col>
          </Row>

          <ModalFooter>
            <CopyToClipboard text={rowContent} onCopy={handleCopy}
            >
              <Button color="primary" className="notification">
                {CopyText}
              </Button>
            </CopyToClipboard>
            <Btn
              attrBtn={{
                color: "secondary",
                onClick: () => setApprovedModal(false),
                type: "button",
              }}
            >
              {Close}
            </Btn>
            <Btn disabled={submitState.loading} attrBtn={{ color: "primary" }}>
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default ApprovedFormModal;
